import styled from 'styled-components';
import { minWidth } from 'lib/breakpoints';
import { rem } from 'lib/units';
import { makeContainer } from 'lib/mixins';

export const Article = styled.article`
    // ${makeContainer(1076)};
    ${makeContainer(960)};
    margin-bottom: ${rem(48)};
    @media (min-width: ${minWidth('lg')}) {
        padding-top: ${rem(24)};
    }
`;

export const Header = styled.header`

`;

export const GalleryContainer = styled.section`

`;

export const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rem(12)};
    margin: ${rem(24)} 0;
    @media (min-width: ${minWidth('md')}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
