import React from 'react';
import Layout from 'components/common/layout';
import { Heading, Text } from 'components/common/typography';
import { Article } from 'components/pages/category/template';
import { generateEmailString, generateTelNo } from 'utils/helpers';
import { Link } from 'components/common/link';
import SEO from 'components/common/seo';

const email = generateEmailString();
const tel = generateTelNo();

const ProductListPage = () => (
    <Layout>
        <SEO 
            title="Contact Us"
        />
        <Article>
            <Heading appearance="trafalgar">Contact Activ</Heading>
            <Text>Ask us anything. From basic questions to complex queries or just say hello.</Text>
            <Text>We are ready to talk to you on <Link as="a" href={`tel:${tel}`}>{tel}</Link> or by email on <Link as="a" href={`mailto:${email}`}>{email}</Link>.</Text>
            <Text>
                Or get in touch via social media.
            </Text>
        </Article>
    </Layout>
);

export default ProductListPage;